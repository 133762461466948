import PWABadge from 'pwa-badge';
import { cookies } from '../cookies';

// Create an Instance
const badgeAPI = new PWABadge();


export class badge {
	static set(value, key) {
        if (badgeAPI.isSupported()) {
            badgeAPI.syncSetBadge(value);
        } else {
            console.log("Badging not supported");
        }
        cookies.set('badge', value);
	}

	static delete() {
		if (badgeAPI.isSupported()) {
            badgeAPI.syncClearBadge();
        } else {
            console.log("Badging not supported");
        }
        cookies.delete('badge');
	}
}
