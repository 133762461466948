import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import ReactDomServer from 'react-dom/server';


import './Reports.scss';
import { none } from 'ol/centerconstraint';

import { metric } from '../../metric';

import { serverUrl, fetchModule } from '../../fetch';

import {ReportListElement, ReportListElementPlaceholder} from '../../atoms/ReportListElement/ReportListElement';

import {Button} from '../../atoms/Button/Button';


import dropdown_icon from "../../assets/icons/dropdown_icon.svg";
import dropdown_icon_up from "../../assets/icons/dropdown_icon_up.svg";
import close_icon from "../../assets/icons/close_icon.svg";



export class Reports extends React.Component {
    constructor(props = {}) {
        // open_edit_form_trigger={this.state.open_edit_form_trigger}
        super();
        console.log("props: ", props);
        this.state = {
            is_mobile: false,
            top_close_bar_visible: false,
            bottom_close_bar_visible: false,
            reports: [],
            file_choice_button_color: "blue",
            upload_placeholder: <></>,
            report_add_info: {
                id: "",
                file_name: "",
                description: "",
                email: ""
            }
        }
    }

    componentDidMount = () => {
        this.get_reports();
        this.check_mobile();
    }

    componentWillUnmount = () => {
      // console.log("do you whant to leave?")
      // alert("do you whant to leave?");
    }

    check_mobile = () => {
        if (window.screen.width <= 800){
            this.setState(state => ({
                ...state, 
                is_mobile: true
            }));
        }
    }


    get_reports = async () => {
        try {
            const response = await fetchModule.doGet({path: '/reports/'});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                let reports = [];
                if (json["reports"]){
                    reports = json["reports"]
                }
                console.log("json: ", json);                
                this.setState(state => ({
                  ...state, 
                  reports: reports,
                }));
                return json;
            } else {
                throw response.status; 
            }
          } catch (error) {
              throw error;
          }
    }

    onReportClickOpen = (id) => {
        window.open("/report/"+id, "_self")
    }

    uploadFile = async (image_data, file_name) => {
        console.log("reader");
        console.log("image_data: ", image_data);
        
        try {

            const formData2 = new FormData();
            formData2.append(
                "file",
                image_data,
                image_data.name
            );


            const response = await fetchModule.doPost(
                {
                    path: '/reports/upload/',
                    body: formData2,
                    use_default_headers: false,
                    body_is_json: false
                });
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("json: ", json);
                
                metric.received_data('/reports/upload/')
                return json;
            }
            
        } catch (error) {
            throw error;
        }
    }

    onInputChange = async (e) => {
        const fileInput = document.getElementById('new_report_file_input');
        if (fileInput.files.length == 0){
            return;
        }
        const fileSize = fileInput.files[0].size;
        const file_name = fileInput.files[0].name;
        const maxSize = 1024 * 1024 * 10; // 10MB
        let report_add_info = this.state.report_add_info;
        report_add_info.file_name = file_name;

        let object = {
            id: -1,
            name: file_name,
            status: "Загрузка"
        };

        // Upload the file
        this.setState(state => ({
            ...state, 
            report_add_info: report_add_info,
            upload_placeholder: <ReportListElementPlaceholder object={object}></ReportListElementPlaceholder>
        }));  

        if (fileSize > maxSize) {
            object = {
                id: -1,
                name: "Файл должен быть меньше 10 МБ, сейчас: "+ (fileSize / (1024 * 1024)).toFixed(2) + " МБ.",
                status: "Ошибка"
            };

            this.setState(state => ({
                ...state, 
                report_add_info: report_add_info,
                error_msg: "Файл должен быть меньше 10 МБ.",
                suggest_msg: "Выбранный файл занимает: "+ (fileSize / (1024 * 1024)).toFixed(2) + " МБ.",
                has_error: true,
                upload_placeholder: <ReportListElementPlaceholder error={true} object={object}></ReportListElementPlaceholder>
            }));  
            return 
        }

        let result = await this.uploadFile(fileInput.files[0], "");
        if (result.status == 201){
            this.get_reports();
            this.setState(state => ({
                ...state, 
                has_error: false,
                upload_placeholder: <></>
            })); 
        }
        // 
    }
  
    render = () => {
      return (
            <>
                <div className="Report-mobile">
                    <div className="Report-mobile-topbar-header" onClick={this.onTopBarOpenerClick}>
                        <a href="/">
                            <h2 className="Report-mobile-topbar-header-element">UXtool</h2>
                        </a>
                        <h3 className="Report-mobile-topbar-header-element">Отчеты</h3>
                    </div>
                    <div className="Report-mobile-center" id="Report-center">
                        <div className='Reports-mobile-center-element_highlighted'>
                            <h3>Создать новый отчет</h3>
                            <input hidden className='Reports-mobile-center--inputfile' type="file" id="new_report_file_input" name="new_report_file_input" accept="image/png, image/jpeg, image/jpg" onChange={this.onInputChange} />
                            <label className="Reports-mobile-center--inputfile--label" htmlFor="new_report_file_input">
                                <Button text={"Выбрать файл"} color={this.state.file_choice_button_color} />
                            </label>    
                        </div>
                        {
                            this.state.reports.map((object, i) => {
                                return <ReportListElement object={object} onClick={this.onReportClickOpen}></ReportListElement>
                                ;
                            })
                        }

                        {this.state.upload_placeholder}

                        
                        
                    </div>
                </div>
            </>
            
        );
      }
  }
  
  export default Reports;