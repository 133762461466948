import React from 'react';
import { Link } from "react-router-dom"

import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom'

import './Sidebar.scss';

import { OvalButton } from '../../atoms/Button/OvalButton';

import logo from "../../assets/icons/logo.svg";
import back_icon from "../../assets/icons/back_icon.svg";
import menu_icon from "../../assets/icons/menu_icon.svg";

import install_icon from "../../assets/icons/install_icon.svg";


import { metric } from '../../metric'
import { cookies } from '../../cookies';


export class Sidebar extends React.Component {
  constructor({id, closeFunc, locationChangeTrigger}) {
    super();
    this.locationChangeTrigger = locationChangeTrigger;
    this.closeFunc = closeFunc;
    this.state = {
      sidebar: <></>
    }
  }

  componentDidMount = () => {
  }

  openAddition = (e) => {
    this.closeFunc();
    this.locationChangeTrigger();
  }


  render = () => {

    let badge = cookies.get('badge');
    console.log("badge: ", badge);
    
    return (
      <div className="sidebar">
        <div className='sidebar--header'>
          <div className="sidebar--header--logo">
            <img src={logo} className="sidebar--header--logo--icon"/>
            <h4>offmaps</h4>
          </div>
          <div className="sidebar--header--close_button">
            <p onClick={this.closeFunc}>Закрыть</p>
          </div>
        </div>
        
        <div className="sidebar--elements">
          <div className="sidebar--elements--element">
            {badge 
            ?
            <div className="sidebar--elements--element--bagde">
              {badge}
            </div>
            :
            <div className="sidebar--elements--element--icon">
            </div>
            }
            
            <Link to={"/add"} onClick={this.openAddition} >
              {badge 
              ? <h4>Продолжить добавление маршрута</h4>
              : <h4>Хочу добавить свой маршрут</h4>
              }
              
            </Link>
            {/* <h4 onClick={this.openAddition}>Хочу добавить свой маршрут</h4> */}
          </div>
          
        </div>
      </div>
    )
  }
};



// <div className="offmaps_logo" >
//         <div className="offmaps_logo--menu">
//             <img src={menu_icon}/>
//           </div>
//           {/* <div className="offmaps_logo--menu">
//             <h3>offmaps</h3>
//           </div> */}
//           <div className="offmaps_logo--logo">
//             <img src={logo} className="logo"/>
//           </div>
//         </div>

//         {this.state.pwa_install_button}