export class cookies {
	static get(key) {
		if (document.cookie.includes(key)){
			let result = "";
			document.cookie.split(" ").forEach((value) => {
				if (value.includes(key)){
					value = value.replace(key+"=", "").replace(";", "");
					result = value;
					return result
				}
			})
			return result
		}
		return undefined;
	}


	static set(key, value, domain=undefined, expires=undefined, path="/") {
		console.log("Cookie: ", key, value);
		let cookie_string = " " + key + "=" + value + ";";
		if (domain) {
			cookie_string += " domain=" + domain + ";";
		}
		if (expires) {
			cookie_string += " expires=" + expires + ";";
		} else {
			// 1 year expiration
			var CookieDate = new Date;
			CookieDate.setFullYear(CookieDate.getFullYear() + 1);
			cookie_string += " expires=" + CookieDate.toUTCString() + ";";
		}
		cookie_string += " path=" +path + ";"
		console.log("Setting string: ", cookie_string);
		
		if (document.cookie.length > 0){
			console.log("Will set: ", cookie_string);
			document.cookie = cookie_string;
		} else {
			document.cookie = cookie_string;
		}
		// console.log("Cookie set: ", document.cookie);
	}

	static delete(key) {
		const expires = "Thu, 01 Jan 1970 00:00:01 GMT";
		this.set(key, "", undefined, expires);
	}
}
