import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./SidebarElement.scss";


// import {Placeholder} from '../Placeholder/Placeholder';

export const SidebarElement = ({text, id, selected, onClick, sidebar_prefix="sidebar"}) => {
    const onClickHandler = (e) => {
        onClick(id)
    }
    let class_name = "Report-" + sidebar_prefix
    class_name = selected ? class_name + "-element_selected" : class_name + "-element"
    return (
        <div key={id} id={"left_side_bar_element_" + id} className={class_name} onClick={onClickHandler}>
            <h3>{text}</h3>
        </div>
    )
}
