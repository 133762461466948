import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./Button.scss";


// import {Placeholder} from '../Placeholder/Placeholder';

export const Button = ({text, disabled, error, color, icon, onClick, row=false}) => {        
    let classname = disabled == true ? "button button--disabled" : "button"
    if (!disabled){
        classname = error == true ? "button button--error" : "button"
    }
    if (row) {
        classname += " button--row";
    } else {
        classname += " button--column";
    }

    if (color) {
        classname += " button--"+color;
    } else {
        classname += " button--primary";
    }
    return (
        <div className={classname} onClick={onClick}>
            {icon 
            ? <img className="button--icon" src={icon}/>
            : <></>
            }
            
            <b>{text}</b>
        </div>
    )
}


// Button.propTypes = {
//     // className: PropTypes.string,
//     color: PropTypes.oneOf(['default', 'primary', 'secondary', 'ternary']),
//     // size: PropTypes.oneOf(['small', 'medium', 'large']),
//     fixed_width: PropTypes.string,
//     fixed_height: PropTypes.string,
//     child: PropTypes.node,
//     onClick: PropTypes.func,
// };


// Button.defaultProps = {
//     onClick: undefined,
// };

