import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { Card, CardAdd } from '../../organisms/Card/Card';

import { Modal } from '../../organisms/Modal/Modal';
import { Subscribe } from '../../organisms/Subscribe/Subscribe';

import './Landing.scss';

import { fetchModule } from '../../fetch'
import { metric } from '../../metric'




// export const Landing = () => {

export class Landing extends React.Component {
    constructor() {
        super();
        this.state = {
            projects_data: [],
            projects_data_loaded: false,
        }
        this.PROJECT_CREATE_MODAL_ID = "project_create_modal_id"
    }

    componentDidMount = () => {
        // this.getProjects();
        metric.page_visit("landing_page");
    }

    cardEditModal = (card_id, title, description, instagram_url) => {
        console.log("cardEditModal___card_id: ", card_id, title, description, instagram_url);
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = "projectslist projectslist--blurred"
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        const project_create_modal_fields = [
            {
                "form_key": "id",
                "name": "",
                "input_type": "hidden",
                "value": card_id
            },
            {
                "form_key": "title",
                "name": "Название проекта / имя эксперта",
                // "description": "какое-то описание",
                "input_type": "text",
                "value": title
            },
            {
                "form_key": "description",
                "name": "Краткое описание запуска",
                "input_type": "textarea",
                "value": description
            },
            {
                "form_key": "instagram_url",
                "name": "Ссылка на Instagram эксперта",
                "description": "Введите instagram через @, например: @producer.tool",
                "input_type": "url",
                "value": instagram_url
            }
        ]
        const project_create_modal = <Modal 
            id={this.PROJECT_CREATE_MODAL_ID} 
            modal_title={"Редактирование: " + title}
            fields={project_create_modal_fields}
            closeFunc={this.closeProjectCreationModal}
            onSubmit={this.onSubmitUpdateProject}
            submitButtonName={"Сохранить"}
        />
        modal_element.render(project_create_modal);
    }

    getProjects = async () => {
        try {
          const response = await fetchModule.doGet({path: '/project/'});
          if ((response.status >= 200) && (response.status < 400)) {
              let json = await response.json();
              this.setState(state => ({
                ...state, 
                projects_data_loaded: true,
                projects_data: json["projects"]
              }));  
              return json;
          }
          else if ((response.status >= 400) && (response.status < 404)) {
            let json = await response.json();
            return json;
          } 
          else {
              throw response.status; 
          }
        } catch (error) {
            throw error;
        }
    }

    closeCardSettingsModal = (card_id) => {
        const element = document.getElementById(card_id + "__card_menu_modal")
        element.hidden = "True";
    }

    closeProjectCreationModal = (e) => {
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = ""
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        modal_element.render(<></>);
    }

    openProjectCreationModal = (e) => {
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = "projectslist projectslist--blurred"
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        const project_create_modal_fields = [
            {
            "form_key": "title",
            "name": "Название проекта / имя эксперта",
            "input_type": "text"
            },
            {
            "form_key": "description",
            "name": "Краткое описание запуска",
            "input_type": "textarea"
            },
            {
            "form_key": "instagram_url",
            "name": "Ссылка на Instagram эксперта",
            "description": "Введите instagram через @, например: @producer.tool",
            "input_type": "url"
            }
        ]
        const project_create_modal = <Modal 
            id={this.PROJECT_CREATE_MODAL_ID} 
            modal_title={"Создание проекта"}
            fields={project_create_modal_fields}
            closeFunc={this.closeProjectCreationModal}
            onSubmit={this.onSubmitNewProject}
        />
        modal_element.render(project_create_modal);
    }

    onSubmitNewProject = async (e) => {
        console.log(e)
        const form_keys_array = ["title", "description", "instagram_url"]
        // for key_name in form_keys_array
        const project_data = {}
        form_keys_array.map((key_name, index) => {
                console.log("_________: ", this.PROJECT_CREATE_MODAL_ID + "_" + key_name);
                const element = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + key_name)
                console.log(key_name, ": ", element.value);
                project_data[key_name] = element.value;
            }
        )
        console.log("project_data: ", project_data);

        try {
            const response = await fetchModule.doPost({path: '/project/', body: project_data});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                  ...state, 
                  projects_data: json["projects"]
                }));
                this.closeProjectCreationModal(null);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
    }

    onSubmitUpdateProject = async (e) => {
        console.log(e)
        const form_keys_array = ["title", "description", "instagram_url"]
        // for key_name in form_keys_array
        const project_data = {}
        form_keys_array.map((key_name, index) => {
                console.log("_________: ", this.PROJECT_CREATE_MODAL_ID + "_" + key_name);
                const element = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + key_name)
                console.log(key_name, ": ", element.value);
                project_data[key_name] = element.value;
            }
        )
        const element_id = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + "id")
        const project_id = element_id.value;
        console.log("project_id: ", project_id);
        console.log("project_data: ", project_data);

        try {
            const response = await fetchModule.doPut({path: '/project/'+project_id + "/", body: project_data});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                  ...state, 
                  projects_data: json["projects"]
                }));
                this.closeProjectCreationModal(null);
                this.closeCardSettingsModal(project_id);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                this.closeCardSettingsModal(project_id);
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
        
    }

    toForm = () => {
        // window.location.hash = "#start_now";
        document.querySelector('#start_now').scrollIntoView({
            behavior: 'smooth'
        });
    }

    toLogin = () => {
        const url = "/login";
        window.open(url, "_self");
    }

    toInst = () => {
        const url = "https://instagram.com/producer.tool";
        window.open(url, "_self");
      }

    render () {
        const start_use_card = {
            color: "orange",
            id: "0",
            title: "Все что нужно на запуске",
            description: "Для всей команды в одном месте:",
            points: [
                "Контент-план",
                "Целевая аудитория",
                "Трекер задач"
                // "ЦА",
                // "Прогревы"
            ],
            has_button_under: true,
            button_text: "Попробовать бесплатно",
            button_sub_text: "21 день, без привязки карты",
            button_error: false,
            button_high: true,
            button_bold_text: true,
            button_color: "green",
            onButtonClick: this.toLogin,
        }

        const bol_card_data = {
            color: "blue",
            id: "0",
            title: "Ты продюсер и запутался в своих материалах по запуску?",
            description: "Можно избежать хаоса, когда всё под рукой:",
            points: [
                "Распаковка",
                "ЦА",
                "Прогревы"
            ],
            has_button_under: true,
            button_text: "Избавиться от хаоса",
            button_error: false,
            onButtonClick: this.toForm,
        }

        const emotions_card_data = {
            color: "orange",
            id: "1",
            title: "С Инструментом продюсера запуски – проще!",
            description: "Инструмент возьмёт рутину на себя и позволит тебе сфокусироваться на главном:",
            points: [
                "Писать контент план, а не искать его",
                "Знать, кто целевая аудитория, а не вспоминать",
                "Управлять запуском, а не теряться в нём"
            ],
            has_button_under: true,
            button_text: "Упростить себе запуски",
            button_error: false,
            onButtonClick: this.toForm,
        }

        const pricing_card_data = {
            color: "blue",
            id: "2",
            title: "Тарифы",
            description: "Наш приоритет – польза, которую мы можем вам дать.",
            points: [
                "21 день бесплатного знакомства",
                "Поддержка всегда на связи",
                "Далее – 199 ₽ в месяц"
            ],
            // has_button_under: true,
            // button_text: "Избавиться от хаоса",
            // button_error: true,
            // onButtonClick: undefined,
        }

        // const stories_demo_card_data = {
        //     color: "blue",
        //     id: "3",
        //     title: "Демо сторис",
        //     description: "Рассказать о разделах и показать пример раздела с контент-планом",
        //     has_button_under: true,
        //     button_text: "Избавиться от хаоса",
        //     button_error: true,
        //     onButtonClick: undefined,
        // }
        // const stories_demo_card_data_2 = {
        //     color: "blue",
        //     id: "3_2",
        //     title: "Демо сторис 2",
        //     description: "Рассказать о разделах и показать пример раздела с контент-планом",
        //     has_button_under: true,
        //     button_text: "Избавиться от хаоса",
        //     button_error: true,
        //     onButtonClick: undefined,
        // }

        const for_whom_card_data = {
            color: "violet",
            id: "4",
            title: "Для кого этот инструмент?",
            description: "В первую очередь инструмент создан для продюсеров запусков. Он тебе подойдет, если:",
            points: [
                "Только начинаешь свой путь продюсера",
                "Уже делала запуск и поняла, что запуталась в нём",
                "Хочешь привести свои запуски в порядок",
                "Ищешь пространство для работы с экспертом"
            ],
            has_button_under: true,
            button_text: "Мне подходит",
            button_error: false,
            onButtonClick: this.toForm,
        }

        // const how_to_start_card_data = {
        //     color: "red",
        //     id: "5",
        //     title: "Как начать пользоваться инструментом?",
        //     description: "Рассказать о разделах и показать пример раздела с контент-планом",
        //     has_button_under: true,
        //     button_text: "Начать пользоваться",
        //     button_error: true,
        //     onButtonClick: undefined,
        // }
        
        const about_me_start_card_data = {
            color: "red",
            id: "6",
            title: "Познакомимся?",
            description: 'Создатели инструмента прошли курс "Академии запусков" от @nastya.pixy и поняли, что им нехватало именно такого инструмента. Именно поэтому решили его создать. Подробнее про наш путь рассказываем в Инстаграмме, подпишись!',
            instagram_url: "producer.tool",
            has_button_under: true,
            button_text: "Подписаться на Instagram",
            button_error: false,
            onButtonClick: this.toInst,
        }
        return(
            <div className='Landing'>
                <h1>Войдите, чтобы попробовать UX tool</h1>
                <a className='Landing-button' href="/login"><h2>Войти</h2></a>
                {/* <div className='landing_header_background'></div>
                <div className="landing_list">
                    <div id="landing_list">
                        <div className="landing_list--text">
                            <h1>Инструмент продюсера</h1>
                        </div>
                        
                        <div className="landing_list--body-dark">
                            <div className="landing_list--card">
                                <h2 className="projectslist--text">
                                    Управляй запусками в легкости
                                </h2>
                                <br></br>
                                <Card 
                                    nomargin={true}
                                    card_type={"project"}
                                    key={'header'}
                                    has_sub_menu={false}
                                    card_prefix_url={""}
                                    {...start_use_card}
                                />
                            </div>
                        </div>
                        <div className="landing_list--body">
                            <Card 
                                card_type={"project"}
                                key={'bol'}
                                has_sub_menu={false}
                                card_prefix_url={""}
                                {...bol_card_data}
                            />
                            <br></br>
                            <Card 
                                card_type={"project"}
                                key={'emotions'}
                                has_sub_menu={false}
                                card_prefix_url={""}
                                {...emotions_card_data}
                            />
                            <br></br>
                            <Card 
                                card_type={"project"}
                                key={'try_stories'}
                                has_sub_menu={false}
                                card_prefix_url={""}
                                {...for_whom_card_data}
                            />
                            <br></br>
                            <Card 
                                card_type={"project"}
                                key={'pricing'}
                                has_sub_menu={false}
                                card_prefix_url={""}
                                {...pricing_card_data}
                            />
                            
                            {/* <Card 
                                card_type={"project"}
                                key={'try_stories'}
                                has_sub_menu={false}
                                card_prefix_url={""}
                                {...how_to_start_card_data}
                            /> 
                        </div>
                        <div className="landing_list--body-dark">
                            <h2 id="start_now" className="projectslist--text"></h2>
                            <br></br>
                            <br></br>
                            
                            <div className="card">
                                <h2 className="projectslist--text">
                                    Начни пользоваться инструментом продюсера
                                </h2>
                                <Subscribe 
                                    id={"subscr"}
                                />
                            </div>
                            <br></br>
                            <br></br>
                        </div>
                            
                        <div className="landing_list--body">
                            <Card 
                                card_type={"project"}
                                key={'try_stories'}
                                has_sub_menu={false}
                                card_prefix_url={""}
                                {...about_me_start_card_data}
                            />
                        </div>
                    </div>
                </div> */}
            </div>
            
        );
    }
}
