import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./CloseButton.scss";


// import {Placeholder} from '../Placeholder/Placeholder';

export const CloseButton = ({onClick, text="Закрыть"}) => {        
    return (
        <div className="close_button" onClick={onClick}>
            <p>{text}</p>
        </div>
    )
}


// Button.propTypes = {
//     // className: PropTypes.string,
//     color: PropTypes.oneOf(['default', 'primary', 'secondary', 'ternary']),
//     // size: PropTypes.oneOf(['small', 'medium', 'large']),
//     fixed_width: PropTypes.string,
//     fixed_height: PropTypes.string,
//     child: PropTypes.node,
//     onClick: PropTypes.func,
// };


// Button.defaultProps = {
//     onClick: undefined,
// };

