import { fetchModule } from './fetch'
import reportWebVitals from './reportWebVitals'

window.reportWebVitals = reportWebVitals
reportWebVitals(console.log)

export class metric {
	static npev (event = "", payload = {}, input=1) {  // input = 1 – данные вводятся или 0 – данные получены
		const metrics_data = {
			"st": "client",
			"ste": String(Date()),
			"e": event,
			"et": input,
			"v": payload
		}
		fetchModule.doPost({path: "/metrics/npev/", body: metrics_data});
		return 
	}

	static async anpev (event = "", payload = {}, input=1) {  // input = 1 – данные вводятся или 0 – данные получены
		const metrics_data = {
			"st": "client",
			"ste": String(Date()),
			"e": event,
			"et": input,
			"v": payload
		}
		await fetchModule.doPost({path: "/metrics/npev/", body: metrics_data});
		return 
	}

	static page_visit (page) {
		const metrics_data =  {
			"page": page,
			"url": window.location.pathname + window.location.search
		}
		return this.npev("page_visit", metrics_data, 1);
	}

	static page_focus (page) {
		const metrics_data =  {
			"page": page,
			"url": window.location.pathname + window.location.search
		}
		return this.npev("page_focus", metrics_data, 1);
	}

	static page_blur (page) {
		const metrics_data =  {
			"page": page,
			"url": window.location.pathname + window.location.search
		}
		return this.npev("page_blur", metrics_data, 1);
	}

	static page_load (page) {
		const metrics_data =  {
			"start_page": page,
			"url": window.location.pathname + window.location.search
		}
		return this.npev("page_load", metrics_data, 1);
	}

	static page_leave (page) {
		const metrics_data =  {
			"page": page,
			"url": window.location.pathname + window.location.search
		}
		return this.npev("page_leave", metrics_data, 1);
	}

	static error (page, payload) {
		const metrics_data =  {
			"page": page,
			"url": window.location.pathname + window.location.search,
			"payload": payload
		}
		return this.npev("error", metrics_data, 1);
	}

	static click (subject, extra_payload = {}) {
		const metrics_data =  {
			"object": subject,
			"url": window.location.pathname + window.location.search
		}
		Object.assign(metrics_data, extra_payload);
		return this.npev("click_on", metrics_data, 1);
	}

	static received_data (requested_url, payload = {}, extra_payload = {}) {
		const metrics_data =  {
			"url": window.location.pathname + window.location.search,
			"requested_url": requested_url,
			"payload": payload
		}
		Object.assign(metrics_data, extra_payload);
		return this.npev("received_data", metrics_data, 0);
	}

	static sent_data(target_url, payload = {}, extra_payload = {}){
		const metrics_data =  {
			"url": window.location.pathname + window.location.search,
			"target_url": target_url,
			"payload": payload
		}
		Object.assign(metrics_data, extra_payload);
		return this.npev("sent_data", metrics_data, 1);
	}
}


window.onfocus = () => {
	metric.page_focus();
}

window.onblur = () => {
	metric.page_blur();
}

window.addEventListener("load", (event) => {
	metric.page_load();
});

window.addEventListener("beforeunload", (ev) => 
{  
    // ev.preventDefault();
	metric.page_leave();
    // return false; //ev.returnValue = 'Are you sure you want to close?';
});