import React from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom'

import './Header.scss';

import { OvalButton } from '../../atoms/Button/OvalButton';

import logo from "../../assets/icons/logo.svg";
import back_icon from "../../assets/icons/back_icon.svg";
import menu_icon from "../../assets/icons/menu_icon.svg";

import install_icon from "../../assets/icons/install_icon.svg";

import { Sidebar } from '../Sidebar/Sidebar';


import { metric } from '../../metric'
import { badge } from '../../utils/badge';
import { cookies } from '../../cookies';



const onClickBack = () => {
  metric.click("back_button");
  if (window.history.state.idx > 0){
    window.history.back();
  }
}


const onClickLogin = () => {
  metric.click("open_login_button");
  window.open("/login/", "_self");
}

const toForm = () => {
  metric.click("know_first_header_button");
  window.location.hash = "#know_first";
}


// export const Header = ({logged_in}) => {
export class Header extends React.Component {
  constructor({logged_in=false, locationChangeTrigger}) {
    super();
    this.logged_in = logged_in;
    this.locationChangeTrigger = locationChangeTrigger;
    this.state = {
      sidebar: <></>,
      deferredPrompt: null,
      pwa_install_button: <></>,
      header_class_name: "header header--small"
    }
  }

  componentDidMount = () => {
    this.pwaInstallEvent();
  }

  pwaInstallEvent = () => {
    // This variable will save the event for later use.
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      metric.npev("pwa_install_prompt_showed", {"icon_color": "red"});
      e.preventDefault();
      // Save the event because you'll need to trigger it later.
      this.setState(state => ({
        ...state, 
        deferredPrompt: e,
        pwa_install_button: <div className="offmaps_logo--install" onClick={this.showInAppInstallPromotion}>
                              {/* <p>Установить
                                <br></br>
                                приложение
                              </p> */}
                              <OvalButton 
                                    text={"Установить"} 
                                    icon={install_icon}
                                    disabled={false}
                                    row={true}
                              />
                            </div>,
        header_class_name: "header header--large"
      }));  
    });
  }

  showInAppInstallPromotion = async () => {
    // Gather the data from your custom install UI event listener
    // deferredPrompt is a global variable we've been using in the sample to capture the `beforeinstallevent`
    this.state.deferredPrompt.prompt();
    metric.click("pwa_install_button");
    // Find out whether the user confirmed the installation or not
    const { outcome } = await this.state.deferredPrompt.userChoice;
    // The deferredPrompt can only be used once.
    this.setState(state => ({
      ...state, 
      deferredPrompt: null,
      header_class_name: "header header--small"
    }));  
    // Act on the user's choice
    if (outcome === 'accepted') {
      await metric.anpev("pwa_installed");
      console.log('User accepted the install prompt.');
      this.setState(state => ({
        ...state, 
        pwa_install_button: <></>
      })
    )
    } else if (outcome === 'dismissed') {
      metric.npev("pwa_install_dismissed");
      console.log('User dismissed the install prompt');
    }
  }
  // let navigate = useNavigate();

  // const onClickLogo = () => {
  //   navigate("/");
  // }
  

  // клавиша назад не всегда появляется
  // let back_button = "";
  // if (logged_in) {
  //   console.log("window.history.state.idx: ", window.history.state.idx);
  //   if (window.history.state.idx > 0) {
  //     back_button = <div className="sub_menu_button" onClick={onClickBack}>
  //                     <img src={back_icon}/>
  //                   </div>
  //   }
  // }

  closeSidebar = (e) => {
    this.setState(state => ({
        ...state, 
        sidebar: <></>
      })
    )
  }

  openSidebar = (e) => {
    const toRender = <Sidebar locationChangeTrigger={this.locationChangeTrigger} closeFunc={this.closeSidebar}/>
    this.setState(state => ({
        ...state, 
        sidebar: toRender
      })
    )
  }

  render = () => {
    let back_button = ""
    if (this.logged_in){
      back_button = <div className="sub_menu_button" onClick={onClickBack}>
                      <img src={back_icon}/>
                      назад
                    </div>
    } else {
      back_button = <div className="sub_menu_button" onClick={onClickLogin}>
                        <b>Войти</b>
                      </div>
    }

    let badge = cookies.get('badge');
    
    return (
      <div className={this.state.header_class_name}>
        {this.state.sidebar}
        <div className="offmaps_logo" >
        <div className="offmaps_logo--menu" onClick={this.openSidebar}>
            <img src={menu_icon}/>
          </div>
          {/* <div className="offmaps_logo--menu">
            <h3>offmaps</h3>
          </div> */}
          <div className="offmaps_logo--logo">
            <img src={logo} className="logo"/>
          </div>
          {badge ?
          <div className="offmaps_logo--bagde">
            {badge}
          </div>
          : <></>
          }
          
        </div>

        {this.state.pwa_install_button}
      </div>
    )
  }
};

