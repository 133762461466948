import React from 'react';

import { Placeholder } from '../../atoms/Placeholder/Placeholder';

import './MenuViewPlaceholder.scss';

export const MenuViewPlaceholder = () => {
  const header_props = {
    width: "125px",
    height: "60px",
    // margin: "10px 0px 0px 0px",
   
    customStyle: {
      marginTop: "10px",
      marginBottom: "10px",
      borderTopRightRadius: "20px",
      borderBottomRightRadius: "20px"
    },
    rounded: false,
  }


  // margin-left: 10px;
  // margin-right: 10px;
  // margin-top: 20px;
  // margin-bottom: 10px;
  // padding-bottom: 20px;
  // width: 250px;

  const buttonbar_props = {
    width: "calc(100%-45px)",
    height: "125px",
    margin: "10px",
    radius: "200px",
    customStyle: {
      // padding: "20px",
      // paddingLeft: "45px",
      // paddingRight: "45px"
    },
  }

  const feed_style = {
        position: "fixed",
        "zIndex": 1,
        "width": "100%",
        "height": "145px",
        "maxWidth": "600px",
        "bottom": "10px",
    }

  
  return (
    <div id="MenuViewPlaceholder">
      <Placeholder {...header_props}/>
      <div style={feed_style}>
        <Placeholder {...buttonbar_props}/>
      </div>
      
    </div>
  )
}
