import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./OvalButton.scss";


// import {Placeholder} from '../Placeholder/Placeholder';

export const OvalButton = ({text, disabled, error, color, icon, onClick, row=false}) => {        
    let classname = disabled == true ? "oval_button oval_button--disabled" : "oval_button oval_button--primary"
    if (!disabled){
        classname = error == true ? "oval_button oval_button--error" : "oval_button oval_button--primary"
    }
    if (row) {
        classname += " oval_button--row";
    } else {
        classname += " oval_button--column";
    }
    let classname_color = color != undefined ? "oval_button oval_button--" + color : "oval_button oval_button--primary"
    return (
        <div className={classname} onClick={onClick}>
            {icon 
            ? <img className="oval_button--icon" src={icon}/>
            : <div className={classname_color}/>
            }
            
            <p>{text}</p>
        </div>
    )
}


// Button.propTypes = {
//     // className: PropTypes.string,
//     color: PropTypes.oneOf(['default', 'primary', 'secondary', 'ternary']),
//     // size: PropTypes.oneOf(['small', 'medium', 'large']),
//     fixed_width: PropTypes.string,
//     fixed_height: PropTypes.string,
//     child: PropTypes.node,
//     onClick: PropTypes.func,
// };


// Button.defaultProps = {
//     onClick: undefined,
// };

