// import React from 'react';
// import ReactDOM from 'react-dom';
// import PropTypes from 'prop-types';


// import { BigMap } from './pages/BigMap/BigMap';

// import './App.scss';



// export class App extends React.Component {
//     constructor() {
//       super();
//     }
  
//     componentDidMount = () => {
//     }
  
//     componentWillUnmount = () => {
//       // console.log("do you whant to leave?")
//       // alert("do you whant to leave?");
//     }
  
//     render = () => {
//       return (
//             <div className="App">
//               <BigMap/>
//             </div>
//         );
//       }
//   }
  
//   export default App;


import React, {Suspense} from 'react';

import './App.scss';


import { MenuView } from './templates/MenuView/MenuView';

// import { LoginWindow } from './organisms/LoginWindow/LoginWindow';
// import { Header } from './organisms/Header/Header';


import { MenuViewPlaceholder } from './templates/MenuView/MenuViewPlaceholder';

import { fetchModule, domain_name_cookie } from './fetch'

import { cookies } from './cookies';

// import React from 'react';
// import {
//   RecoilRoot,
//   atom,
//   selector,
//   useRecoilState,
//   useRecoilValue,
// } from 'recoil';

// function App() {

export class App extends React.Component {
  constructor() {
    super();
    this.state = {
      auth_status_code: 0,
      auth_message: ""
    }
    this.toRender = <Suspense fallback={<MenuViewPlaceholder/>}>
                      <MenuView logged_in={false} />
                    </Suspense>;
  }

  componentDidMount = () => {
    this.checkAuth()
  }

  componentWillUnmount = () => {
    // console.log("do you whant to leave?")
    // alert("do you whant to leave?");
    
  }

  setupUknownUser = async () => {
    if (!document.cookie.includes("unknown_user_token")){
      try {
        const response = await fetchModule.doGet({path: '/auth/unknown_user_token'});
        if ((response.status >= 200) && (response.status < 400)) {
            let json = await response.json();
            console.log("unknown_user_token: ", json);

            cookies.set("unknown_user_token", json, domain_name_cookie);
            
            return json;
        }
        else {
            throw response.status; 
        }
      } catch (error) {
          throw error;
      }
    }
  }
  
  checkAuth = async () => {
    try {
      const response = await fetchModule.doGet({path: '/auth/check'});
      if ((response.status >= 200) && (response.status < 400)) {
          let json = await response.json();

          const toRender =  <Suspense fallback={<MenuViewPlaceholder/>}>
                    <MenuView logged_in={true} />
                  </Suspense>

          this.setState(state => ({
            ...state, 
            auth_status_code: json["status"],
            auth_message: json["message"],
            toRender: toRender
          }));
          
          return json;
      }
      else if ((response.status >= 400) && (response.status < 404)) {
        let json = await response.json();

        await this.setupUknownUser();
        
        // здесь нужно будет получить токен неизвестного пользователя и проставить в куку
        // и рисуем здесь лендос
        // окно login переедет по пути /login

        const toRender =  <Suspense fallback={<MenuViewPlaceholder/>}>
                            <MenuView logged_in={false} />
                          </Suspense>
        //  <Suspense fallback={<MenuViewPlaceholder/>}>
        //                     <MenuViewPlaceholder />
        //                   </Suspense>
        // <LoginWindow id={"login_window"}/>

        this.setState(state => ({
          ...state, 
          auth_status_code: json["status"],
          auth_message: json["message"],
          toRender: toRender
        }));
        return json;
      } 
      else {
          throw response.status; 
      }
    } catch (error) {
        throw error;
    }
  }

  render = () => {

    let logged_in = false;
    if (this.state.auth_status_code == 200) {
      console.log("Logged in");
      logged_in = true;
    } else {
      console.log("waiting TO AUTH");
    }
    return (
          <div className="App">
            {/* <MenuViewPlaceholder/> */}
            
            {/* <LoginWindow id={"login_window"}/> */}


            {/* <Suspense fallback={<MenuViewPlaceholder/>}> */}
              {/* <MenuView /> */}

            {/* <Header logged_in={logged_in}/> */}
            {/* <MenuViewPlaceholder /> */}
            {this.state.toRender}
            {/* </Suspense> */}
          </div>
      );
    }
}

export default App;
