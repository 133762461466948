import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./ReportListElement.scss";

import { serverUrl } from '../../fetch';


// import {Placeholder} from '../Placeholder/Placeholder';

export const ReportListElement = ({object, onClick}) => {
    const onClickHandler = (e) => {
        onClick(object.id)
    }
    let class_name = "Reports-mobile-center-element"
    let image_source = serverUrl + "/reports/image/" + object.id + "/original.jpg";
    return (
        <div key={object.id} id={"Reports_center_element" + object.id} className={class_name} onClick={onClickHandler}>
            
            <img className='small-image-preview' id={"report_img" + object.id} src={image_source}></img>
            <div className='element-data'>
                <h3>{object.name}</h3>
                <p className='p-light-subtitle'>имя</p>
            </div>
            
            <div className='element-data'>
                <h4>{object.status}</h4>
                <p className='p-light-subtitle'>статус</p>
            </div>
        </div>
    )
}


export const ReportListElementPlaceholder = ({object, onClick, error=false}) => {
    const onClickHandler = (e) => {
        onClick(object.id)
    }
    let class_name = error ? "Reports-mobile-center-element-error" : "Reports-mobile-center-element"
    return (
        <div key={object.id} id={"Reports_center_element" + object.id} className={class_name} onClick={onClickHandler}>
            
            <div className='small-image-preview-placeholder'></div>
            <div className='element-data'>
                <h3>{object.name}</h3>
                <p className='p-light-subtitle'>имя</p>
            </div>
            
            <div className='element-data'>
                <h4>{object.status}</h4>
                <p className='p-light-subtitle'>статус</p>
            </div>
        </div>
    )
}