import React, {Suspense, useEffect} from 'react';
import PropTypes from 'prop-types';

import "./ScreenDrawer.scss";

import difficulty_icon from "../../assets/icons/difficulty.svg";


// function drawImageScaled(img, ctx){
//     var centerShift_x = 0;
//     var centerShift_y = 0;
//     var ratio = 1;
//     console.log("Drawing image");
//     console.log("__ctx: ", ctx);
//     console.log("__this: ", this);
//     var canvas = ctx.canvas ;
//     var hRatio = canvas.width  / img.naturalWidth    ;
//     var vRatio =  canvas.height / img.naturalHeight  ;
//     ratio  = Math.min ( hRatio, vRatio );
//     centerShift_x = ( canvas.width - img.naturalWidth* ratio ) / 2;
//     centerShift_y = ( canvas.height - img.naturalHeight* ratio ) / 2;  
//     console.log("img: ", img);
//     ctx.clearRect(0,0,canvas.width, canvas.height);
//     // ctx.mozImageSmoothingEnabled = false;
//     // ctx.webkitImageSmoothingEnabled = false;
//     // ctx.msImageSmoothingEnabled = false;
//     // ctx.imageSmoothingEnabled = false;
//     ctx.drawImage(img, 0,0, img.naturalWidth, img.naturalHeight,
//                         centerShift_x, centerShift_y,img.naturalWidth*ratio, img.naturalHeight*ratio);  
// }

// function drawImageActualSize(){
//     const canvas = document.getElementById("screen_drawer_canvas");
//     var ctx = canvas.getContext("2d");    
//     drawImageScaled(this, ctx)
// }

// export class ScreenDrawer_old extends React.Component {
//     constructor({id, image_source, onClick}) {
//         super();
//         console.log("image_source: ", image_source);
//         this.image_source = image_source;
//         this.id = id;
//         this.onClick = onClick;
//     }

//     componentDidMount = () => {
//         this.drawOnCanvas();
//     }

//     drawOnCanvas = () => {
//         const canvas = document.getElementById("screen_drawer_canvas");
//         const report_center = document.getElementById("Report-center");
//         canvas.height = report_center.clientHeight;
//         canvas.width = report_center.clientWidth;
//         const image = new Image(600, 600); // Using optional size for image
//         image.onload = drawImageActualSize; // Draw when image has loaded
//         console.log("image_source: ", this.image_source);
//         console.log("image: ", image);
//         image.src = this.image_source;
//     }

    

//     onClickHandler = (e) => {
//         console.log(e);
//         // const screen_drawer_img = document.getElementById("screen_drawer_img");
//         // const rect = screen_drawer_img.getBoundingClientRect();
//         // const x_img = e.clientX - rect.x;
//         // const y_img = e.clientY - rect.y;
//         // const ratio = screen_drawer_img.naturalWidth / screen_drawer_img.clientWidth;
//         // const x = Math.round(x_img * ratio);
//         // const y = Math.round(y_img * ratio);
//         // const coords = [x, y];
//         // this.onClick(this.id, coords)
//     }

//     onClickHandler_img = (e) => {
//         const screen_drawer_img = document.getElementById("screen_drawer_img");
//         const rect = screen_drawer_img.getBoundingClientRect();
//         const x_img = e.clientX - rect.x;
//         const y_img = e.clientY - rect.y;
//         const ratio = screen_drawer_img.naturalWidth / screen_drawer_img.clientWidth;
//         const x = Math.round(x_img * ratio);
//         const y = Math.round(y_img * ratio);
//         const coords = [x, y];
//         this.onClick(this.id, coords)
//     }

//     render = () => {
//         return (
//             <div className="ScreenDrawer">
//                 {/* <img id={"screen_drawer_img"} src={image} onClick={onClickHandler}></img> */}
//                 <canvas id={"screen_drawer_canvas"} onClick={this.onClickHandler}></canvas>
//             </div>
//         );
//     }

// }







export const ScreenDrawer = ({id, image_source, onClick}) => {      
    const onClickHandler = (e) => {
        const screen_drawer_img = document.getElementById("screen_drawer_img");
        const rect = screen_drawer_img.getBoundingClientRect();
        const x_img = e.clientX - rect.x;
        const y_img = e.clientY - rect.y;
        const ratio_width = screen_drawer_img.naturalWidth / screen_drawer_img.clientWidth;
        const ratio_height = screen_drawer_img.naturalHeight / screen_drawer_img.clientHeight;
        const ratio = Math.max(ratio_width, ratio_height)
        console.log("ratio: ", ratio, ratio_width, ratio_height);
        const x = Math.round(x_img * ratio);
        const y = Math.round(y_img * ratio);
        const coords = [x, y];
        onClick(id, coords)
    }
    
    return (
        <div className="ScreenDrawer" id="screen_drawer_container">
            <img id={"screen_drawer_img"} src={image_source} onClick={onClickHandler}></img>
        </div>
        
    )
}

